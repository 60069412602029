import dynamic from "next/dynamic";
import {ParagraphHero} from "./paragraph--hero";
import {ParagraphProductFinder} from "./paragraph--product-finder";

// Dynamic Components.
const ParagraphWysiwyg = dynamic(() => import('./paragraph--wysiwyg').then((mod) => mod.ParagraphWysiwyg));
const ParagraphAccordion = dynamic(() => import('./paragraph--accordion').then((mod) => mod.ParagraphAccordion));
const ParagraphSectionHeader = dynamic(() => import('./paragraph--section-header').then((mod) => mod.ParagraphSectionHeader));
const ParagraphPressReleases = dynamic(() => import('./paragraph--press_releases').then((mod) => mod.ParagraphPressReleases));
const ParagraphBenefitCard = dynamic(() => import('./paragraph--benefit-card').then((mod) => mod.ParagraphBenefitCard));
const ParagraphNewsArticle = dynamic(() => import('./paragraph--news-article').then((mod) => mod.ParagraphNewsArticle));
const ParagraphFrostedDetailCard = dynamic(() => import('./paragraph--frosted-detail-card').then((mod) => mod.ParagraphFrostedDetailCard));
const ParagraphCareersGrid = dynamic(() => import('./paragraph--careers-grid').then((mod) => mod.ParagraphCareersGrid));
const ParagraphImageAsideCta = dynamic(() => import('./paragraph--image-aside-cta').then((mod) => mod.ParagraphImageAsideCta));
const ParagraphWebform = dynamic(() => import('./paragraph--webform').then((mod) => mod.ParagraphWebform));
const ParagraphRecentNews = dynamic(() => import('./paragraph--recent-news').then((mod) => mod.ParagraphRecentNews));
const ParagraphJumpButtonGrid = dynamic(() => import('./paragraph--jump-button-grid').then((mod) => mod.ParagraphJumpButtonGrid));
const ParagraphPricingTable = dynamic(() => import('./paragraph--pricing-table').then((mod) => mod.ParagraphPricingTable));
const ParagraphTable = dynamic(() => import('./paragraph--table').then((mod) => mod.ParagraphTable));
const ParagraphIndustrySegmentSlider = dynamic(() => import('./paragraph--industry-segment-slider').then((mod) => mod.ParagraphIndustrySegmentSlider));
const ParagraphIconButtons = dynamic(() => import('./paragraph--icon-buttons').then((mod) => mod.ParagraphIconButtons));
const ParagraphPlaceMap = dynamic(() => import("../paragraphs/paragraph--place-map").then((mod) => mod.ParagraphPlaceMap), {ssr: false});
const ParagraphPersonGrid = dynamic(() => import("../paragraphs/paragraph--person-grid").then((mod) => mod.ParagraphPersonGrid));
const ParagraphAffiliateSlider = dynamic(() => import("../paragraphs/paragraph--affiliate_slider").then((mod) => mod.ParagraphAffiliateSlider));
const ParagraphContactUsForm = dynamic(() => import("../paragraphs/paragraph--contact_us_form").then((mod) => mod.ParagraphContactUsForm));
const ParagraphVideoBlock = dynamic(() => import("../paragraphs/paragraph--video-block").then((mod) => mod.ParagraphVideoBlock));
const ParagraphImageBlock = dynamic(() => import("../paragraphs/paragraph--image-block").then((mod) => mod.ParagraphImageBlock));
const ParagraphTimeline = dynamic(() => import("../paragraphs/paragraph--timeline").then((mod) => mod.ParagraphTimeline));
const ParagraphDocumentLibrary = dynamic(() => import("../paragraphs/paragraph--document-library").then((mod) => mod.ParagraphDocumentLibrary));
const ParagraphProductGrid = dynamic(() => import("../paragraphs/paragraph--product-grid").then((mod) => mod.ParagraphProductGrid));
const ParagraphEvents = dynamic(() => import("../paragraphs/paragraph--events").then((mod) => mod.ParagraphEvents));
const ParagraphDetailCard = dynamic(() => import("../paragraphs/paragraph--detail-card").then((mod) => mod.ParagraphDetailCard));
const ParagraphRLUCalculator = dynamic(() => import("../paragraphs/paragraph--rlu-calculator").then((mod) => mod.ParagraphRLUCalculator));
const ParagraphButton = dynamic(() => import("../paragraphs/paragraph--button").then((mod) => mod.ParagraphButton));
const ParagraphJumpMenu = dynamic(() => import('./paragraph--jump-menu').then((mod) => mod.ParagraphJumpMenu));
const ParagraphFeaturedEvent = dynamic(() => import('./paragraph--featured-event').then((mod) => mod.ParagraphFeaturedEvent));
const ParagraphFeaturedSolutionsSlider = dynamic(() => import('./paragraph--featured-solutions-slider').then((mod) => mod.ParagraphFeaturedSolutionsSlider));
const ParagraphCategoryBox = dynamic(() => import('./paragraph--category-box').then((mod) => mod.ParagraphCategoryBox));
const ParagraphSolrVideoGrid = dynamic(() => import('./paragraph--solr-video-grid').then((mod) => mod.ParagraphSolrVideoGrid));
const ParagraphSPAWithSearchFilter = dynamic(() => import('./paragraph--spa-search-with-filters').then((mod) => mod.ParagraphSPAWithSearchFilter));
const ParagraphFAQ = dynamic(() => import('./paragraph--faqs').then((mod) => mod.ParagraphFaqs));
const ParagraphVideoSlider = dynamic(() => import('./paragraph--video-slider').then((mod) => mod.ParagraphVideoSlider));
const ParagraphSolrDocumentLibrary = dynamic(() => import('./paragraph--solr-document-library').then((mod) => mod.ParagraphSolrDocumentLibrary));
const ParagraphROICalculator = dynamic(() => import('./paragraph--roi-calculator').then((mod) => mod.ParagraphROICalculator));
const Paragraph3dCarousel = dynamic(() => import('./paragraph--3d-carousel').then((mod) => mod.Paragraph3dCarousel));

/**
 * A generic paragraph loader, will check for a field_content or field_paragraphs field
 * and render recursively.
 *
 * @param node
 * @param props
 * @constructor
 */
export function Paragraph ({ paragraph, ...props }) {
  return (
    <>
      {paragraph.type === 'paragraph--wysiwyg' && <ParagraphWysiwyg paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--product_grid' && <ParagraphProductGrid paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--accordion' && <ParagraphAccordion paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--events' && <ParagraphEvents paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--press_releases' && <ParagraphPressReleases paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--detail_card' && <ParagraphDetailCard paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--section_header' && <ParagraphSectionHeader paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--hero' && <ParagraphHero paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--benefit_card' && <ParagraphBenefitCard paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--news_article' && <ParagraphNewsArticle paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--contact_us_form' && <ParagraphContactUsForm paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--video_block' && <ParagraphVideoBlock paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--image_block' && <ParagraphImageBlock paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--frosted_detail_card' && <ParagraphFrostedDetailCard paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--careers_grid' && <ParagraphCareersGrid paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--image_aside_cta' && <ParagraphImageAsideCta paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--webform' && <ParagraphWebform paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--product_finder' && <ParagraphProductFinder paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--recent_news' && <ParagraphRecentNews paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--jump_button_grid' && <ParagraphJumpButtonGrid paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--pricing_table' && <ParagraphPricingTable paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--table' && <ParagraphTable paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--document_library' && <ParagraphDocumentLibrary paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--industry_segment_slider' && <ParagraphIndustrySegmentSlider paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--person_grid' && <ParagraphPersonGrid paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--place_map' && <ParagraphPlaceMap paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--affiliate_slider' && <ParagraphAffiliateSlider paragraph={paragraph} {...props}/> }
      {paragraph.type === 'paragraph--timeline' && <ParagraphTimeline paragraph={paragraph} {...props}/> }
      {paragraph.type === 'paragraph--icon_buttons' && <ParagraphIconButtons paragraph={paragraph} {...props}/> }
      {paragraph.type === 'paragraph--rlu_calculator' && <ParagraphRLUCalculator paragraph={paragraph} {...props}/> }
      {paragraph.type === 'paragraph--button' && <ParagraphButton paragraph={paragraph} {...props}/> }
      {paragraph.type === 'paragraph--jump_menu' && <ParagraphJumpMenu paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--featured_event' && <ParagraphFeaturedEvent paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--featured_solutions_slider' && <ParagraphFeaturedSolutionsSlider paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--category_box' && <ParagraphCategoryBox paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--solr_video_grid' && <ParagraphSolrVideoGrid paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--spa_search_with_filters' && <ParagraphSPAWithSearchFilter paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--faqs' && <ParagraphFAQ paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--video_slider' && <ParagraphVideoSlider paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--solr_document_library' && <ParagraphSolrDocumentLibrary paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--roi_calculator' && <ParagraphROICalculator paragraph={paragraph} {...props}/>}
      {paragraph.type === 'paragraph--3d_carousel' && <Paragraph3dCarousel paragraph={paragraph} {...props}/>}
    </>
  )
}