import {Hero} from "../../organisms/hero";
import {Fragment} from "react";
import {Button} from "../../atoms/button";
import {FrostedGlassContainer} from "../../atoms/gradient-container";
import classNames from "classnames";
import {HeroHeading} from "../../atoms/heading";
import dynamic from "next/dynamic";
import {BreadCrumb, DrupalLink} from "../../../types/hygiena-types";
import {Link} from "../../atoms/link";

const FormattedText = dynamic(() => import("../../formatted-text").then((mod) => mod.FormattedText));

export function ParagraphHero ({paragraph, ...props}) {
  const video = paragraph?.field_hero_video;
  const depthMap = paragraph?.field_hero_depth_map;
  const jumpClass = paragraph?.field_hero_jump_class;
  const alignment = paragraph?.field_hero_alignment ?? "left";
  const breadcrumbLinks: DrupalLink[] = paragraph?.field_hero_breadcrumbs ?? [];
  const collapsed = paragraph?.field_hero_collapse;

  const breadcrumbs: BreadCrumb[] = [];
  for (const link of breadcrumbLinks) {
    breadcrumbs.push({
      id: link.url,
      name: link.title,
      url: link.url,
    });
  }

  // @deprecated The field_hero_link and jump class will be removed.
  const jumpCallback = () => {
    if (typeof window === "undefined" || typeof document === "undefined" || !jumpClass) return;
    const element = document.querySelector(`.${jumpClass}`);
    if (element) element.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <Fragment key={`paragraph--hero-${paragraph.id}`}>
      <Hero image={paragraph.field_hero_image} title={paragraph.field_hero_title} video={video} depthMap={depthMap} collapsed={collapsed}>
        <div className={classNames(
          "container max-md:py-20 mx-auto min-h-fit flex items-center md:px-6 2xl:px-0", {
            "justify-start": alignment === "left",
            "justify-center": alignment === "center",
            "justify-end": alignment === "right",
            "md:py-20": !collapsed,
            "md:py-6": collapsed,
          })}>
          <FrostedGlassContainer className="p-8 md:p-12 rounded-sm drop-shadow xl:mx-0 mx-2 md:w-3/5">
            {breadcrumbs?.length ? (
              <div className="text-primary font-semibold mb-4">
                {breadcrumbs.map((item, index) => {
                  if (item.url) {
                    return (
                      <div className="inline" key={`breadcrumb--${item.id}`}>
                        <Link href={item.url ?? "/"} className="hover:text-white transition-all">{item.name}</Link>
                        {index + 1 < breadcrumbs.length && (<span className="px-1.5">/</span>)}
                      </div>
                    )
                  }
                })}
              </div>
            ): <></>}
            {!breadcrumbs?.length && paragraph?.field_hero_caption?.length ? (
              <div className="caption text-primary font-bold text-2xl pl-1 pb-6">{paragraph.field_hero_caption}</div>
            ) : <></>}
            <HeroHeading className="mb-6">
              {paragraph.field_hero_title}
            </HeroHeading>
            <div className="mb-12 font-light text-black tracking-snug leading-8 md:text-xl">
              {paragraph?.field_hero_description?.processed && (
                <FormattedText processed={paragraph.field_hero_description.processed}/>
              )}
            </div>
            <div className="flex flex-row gap-2 flex-wrap">
              {/* @deprecated The field_hero_link and jump class will be removed. */}
              {(paragraph?.field_hero_link?.title && paragraph?.field_hero_link?.url && !jumpClass?.length) ? (
                <Button href={paragraph.field_hero_link.url}
                        className={classNames("max-md:w-full", paragraph?.field_hero_button_class ?? "")}
                        containerClassName={"max-md:w-full"}
                        color="primary" hover={{color: "primary"}}
                        position="right">{paragraph.field_hero_link.title}</Button>
              ) : <></>}
              {/* @deprecated The field_hero_link and jump class will be removed */}
              {(paragraph?.field_hero_link?.title && paragraph?.field_hero_link?.url && jumpClass?.length) ? (
                <Button callback={jumpCallback} icon="arrow-down"
                        height={20} width={20}
                        className={classNames("max-md:w-full", paragraph?.field_hero_button_class ?? "")}
                        containerClassName={"max-md:w-full"}
                        color="primary" hover={{color: "primary"}}
                        position="right">{paragraph.field_hero_link.title}</Button>
              ) : <></>}
              {paragraph?.field_hero_buttons?.length ? paragraph.field_hero_buttons.map((item, index) => {
                const jumpLink = !!item?.url?.match(/^#/);
                return (
                  <Button
                    key={`${paragraph?.id}--button--${index}`}
                    href={item?.url} icon={jumpLink ? "arrow-down" : undefined}
                    height={20} width={20}
                    callback={(event) => {
                      if (jumpLink) {
                        event.preventDefault();
                        const element = document.getElementById(item.url.replace("#", ""));
                        if (element) element.scrollIntoView({behavior: 'smooth'})
                      }
                    }}
                    className={classNames("max-md:w-full", paragraph?.field_hero_button_class ?? "")}
                    containerClassName={"max-md:w-full"}
                    color="primary" hover={{color: "primary"}} position="right">{item?.title}</Button>
                )
              }) : <></>}
            </div>
          </FrostedGlassContainer>
        </div>
      </Hero>
    </Fragment>
  );
}