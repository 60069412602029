import {backgroundStyles, backgroundTextColor, SectionRegions} from "./section-regions";
import classNames from "classnames";
import {CSSProperties} from "react";

export function SectionSection ({section, sectionMap, first, nestedSections, ...props}) {
  const background = section?.field_section_background;
  const backgroundStyle = backgroundStyles[background] ?? "";
  const textColor = backgroundTextColor[background] ?? "text-black";
  const style: CSSProperties = {};

  if (section?.field_section_margin_top) style.marginTop = `${section?.field_section_margin_top}rem`;
  if (section?.field_section_margin_bottom) style.marginBottom = `${section?.field_section_margin_bottom}rem`;
  if (section?.field_section_padding_top) style.paddingTop = `${section?.field_section_padding_top}rem`;
  if (section?.field_section_padding_bottom) style.paddingBottom = `${section?.field_section_padding_bottom}rem`;
  if (section?.field_section_gap) style.gap = `${section.field_section_gap}rem`;
  if (section?.field_section_wrap) style.flexWrap = "wrap";
  if (section?.field_section_padding) {
    style.paddingLeft = `${section?.field_section_padding}rem`;
    style.paddingRight = `${section?.field_section_padding}rem`;
  }

  return (
    <section id={section?.field_section_id ?? undefined} style={style} className={classNames(
      "layout-section flex flex-col w-full",
      {
        "py-12": !first, "scroll-m-20": section?.field_section_jump_class || section?.field_section_id,
        "drop-shadow-sm": section?.field_section_drop_shadow,
      },
      section?.field_section_jump_class ?? "",
      backgroundStyle
    )}>
      <SectionRegions sectionMap={sectionMap} section={section} nestedSections={nestedSections} textColor={textColor} />
    </section>
  )
}
